import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      auditoria: this.$store.getters.accionesAuditoria,
      telefonos: [
        "Bogotá: (601) 743 1132",
        "Cali: (602) 485 2360",
        "Medellín: (604) 540 9978",
        "Barranquilla: (605) 309 3003",
      ],
    };
  },
  methods: {
    getHash(txt) {
      return CryptoJS.SHA256(txt).toString(CryptoJS.enc.Hex);
    },
    validarCampo(val) {
      const MSG = "Este campo",
        VN = "valores numéricos",
        CE = "caracteres especiales",
        LC = `de ${val.numeric !== undefined ? "digitos" : "caracteres"} es de`;

      // MAIN VALIDATORS
      if (val.required !== undefined ? !val.required : false) return `${MSG} es obligatorio`;

      if (val.numeric !== undefined ? !val.numeric : false) return `${MSG} solo acepta ${VN}`;

      if (val.alphaNum !== undefined ? !val.alphaNum : false) return `${MSG} no acepta ${CE}`;

      if (val.alpha !== undefined ? !val.alpha : false) return `${MSG} no acepta ${VN} o ${CE}`;

      if (val.sameAs !== undefined ? !val.sameAs : false) return `Los campos deben ser iguales`;

      if (val.email !== undefined ? !val.email : false) return `El formato de correo electrónico es incorrecto`;

      if (val.maxLength !== undefined ? !val.maxLength : false) return `El máximo ${LC} ${val.$params.maxLength.max}`;

      if (val.minLength !== undefined ? !val.minLength : false) return `El minimo ${LC} ${val.$params.minLength.min}`;

      // CUSTOM VALIDATORS
      if (val.celValido !== undefined ? !val.celValido : false) return `El número de celular no es valido`;

      return "";
    },
    onlyNumber(evt) {
      if (evt.keyCode === 13) return true;

      const regex = new RegExp(/^[0-9]*$/gi);
      if (!regex.test(evt.key)) evt.preventDefault();
    },
    validarCorreo(tag) {
      const emailRegex = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      return emailRegex.test(tag);
    },
    validarVacio(value) {
      return value === null || value === undefined ? "" : value;
    },
    maskCelular(value) {
      return this.formatCelular(value).replace(/([0-9]{3})([0-9]{3})([0-9]{4})/g, "($1) $2-$3");
    },
    formatCelular(value) {
      return value.replace(/[^0-9]/g, "");
    },
    upperCase(evt) {
      evt.target.value = evt.target.value.toUpperCase();
    },
    nextStep() {
      if (this.activeStep < this.steps.length) {
        this.activeStep += 1;
        this.flowStep += 1;
      }
    },
    setStep(val) {
      if (val <= this.flowStep) {
        this.activeStep = val;
      }
    },
    makeAlertArray(desc, lista) {
      const h = this.$createElement;
      const mensajeList = lista.map((mensaje) => h("li", {}, mensaje));
      const mensajeCompleto = [h("p", { class: "text-justify mb-1" }, desc), h("ul", { class: "pl-4" }, mensajeList)];

      return mensajeCompleto;
    },
  },
};
