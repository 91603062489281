var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _c("b-col", { attrs: { cols: "12" } }, [
        _c("p", { staticClass: "primary fs-24 fw-bold py-2" }, [
          _vm._v(" Habla con un asesor "),
        ]),
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "btn-wp content-start-center fs-22 fs-mobile-14",
              on: { click: _vm.openWhatsapp },
            },
            [
              _c("i", {
                staticClass: "lab la-whatsapp fs-30 fs-mobile-18 mr-2",
              }),
              _vm._v(" WHATSAPP "),
            ]
          ),
        ],
        1
      ),
      _c(
        "b-col",
        {
          staticClass: "content-start-center",
          attrs: { cols: "12", md: "8", lg: "7", xl: "6" },
        },
        [
          _c("img", {
            staticClass: "py-3",
            attrs: {
              width: "110px",
              src: require("@/assets/images/anothers/abbyLogo.png"),
            },
          }),
          _c("div", { staticClass: "p-3" }, [
            _c(
              "p",
              { staticClass: "primary mb-0 pt-3 fs-20 fs-mobile-16 fw-bold" },
              [_vm._v("Hola soy Abby, tu asistente virtual vía Whatsapp.")]
            ),
            _c(
              "p",
              { staticClass: "primary mb-0 fs-30 fs-mobile-18 fw-bold" },
              [
                _vm._v(
                  " (+" +
                    _vm._s(_vm.info.prefijoContactoWsp) +
                    ") " +
                    _vm._s(_vm.info.numeroContactoWsp) +
                    ". "
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-button",
            {
              staticClass: "content-start-center fs-22 fs-mobile-14",
              attrs: { variant: "danger" },
              on: { click: _vm.openWhatsapp },
            },
            [
              _c("i", { staticClass: "la la-phone fs-30 fs-mobile-18 mr-2" }),
              _vm._v(" LÍNEAS DE ATENCIÓN "),
            ]
          ),
          _c(
            "ul",
            {
              staticClass:
                "primary pl-4 py-3 fs-20 fs-mobile-16 fw-bold text-coutas",
            },
            _vm._l(_vm.telefonos, function (tel, idx) {
              return _c("li", { key: idx, staticClass: "py-1" }, [
                _vm._v(_vm._s(tel)),
              ])
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }